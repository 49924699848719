import React from "react";

const CricketPoster = () => {
  return (
    <div className="w-full h-[90vh] bg-center bg-no-repeat">
      {/* Background Image */}
      <img className="relative w-full h-screen -z-10" src="/statium pic.jpg" alt="" />

      {/* Content Section */}
      <div className="grid grid-cols-1 md:grid-cols-12 z-10 text-black md:gap-6 top-0 left-0 absolute  md:p-4">
        {/* Left Side (Text and Button) */}
        <div className="text-start md:col-span-4 space-y-4 w-full h-auto md:h-[44vh] md:mt-60 text-white bg-white/40 opacity-70 p-4 rounded-md backdrop-blur-sm">
          <h1 className="text-3xl sm:text-4xl md:text-5xl text-transparent bg-clip-text bg-gradient-to-bl from-orange-700 via-gray-900 to-red-600 font-serif font-bold uppercase">
            Create Your Dream Cricket Team
          </h1>
          <p className="text-gray-900 font-semibold text-base sm:text-lg">
            Pick your favorite players, make predictions, and earn rewards!
          </p>
          <button className="bg-orange-500 animate-bounce text-white px-6 sm:px-8 py-2 sm:py-3 rounded-md">
            Get Started
          </button>
        </div>

        {/* Right Side (Image) */}
        <div className="md:col-span-8  text-end">
          <img className="w-full  items-center h-[70vh] md:h-screen  object-cover" src="/cricketer.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default CricketPoster;
